  <template>
    <nav class="bg-white shadow-md fixed top-0 left-0 w-full z-50">
      <div class="mx-auto max-w-6xl flex justify-between items-center py-3 px-4">
        <!-- Left Side: Logo and Company Name -->
        <div class="flex items-center space-x-4">
          <router-link to="/" class="flex items-center space-x-4">
            <img src="@/assets/ziontech.jpg" alt="Zion Tech" class="h-10 w-10 cursor-pointer" />
            <!-- Optional: Display the name next to the image -->
            <!-- <span class="text-2xl font-bold text-gray-800">Zion Tech</span> -->
          </router-link>
        </div>


        <!-- Right Side: Navigation Links (Hidden on Mobile) -->
        <div class="hidden md:flex space-x-8">
          <router-link
            to="/"
            class="nav-link"
            :class="{ 'active': isActive('/') }"
          >
            Home
          </router-link>
          <!-- <router-link
            to="/about"
            class="nav-link"
            :class="{ 'active': isActive('/about') }"
          >
            About Us
          </router-link> -->
          <router-link
            to="/service"
            class="nav-link"
            :class="{ 'active': isActive('/service') }"
          >
            Services
          </router-link>
          <!-- <router-link
            to="/contact"
            class="nav-link"
            :class="{ 'active': isActive('/contact') }"
          >
            Contact
          </router-link> -->
        </div>

        <!-- Hamburger Icon (Visible on Mobile) -->
        <div class="md:hidden">
          <button @click="toggleMenu" class="focus:outline-none">
            <svg
              v-if="!menuOpen"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              class="h-8 w-8 text-gray-800"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              class="h-8 w-8 text-gray-800"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>

      <!-- Mobile Menu (Visible when the hamburger icon is clicked) -->
      <div
        v-if="menuOpen"
        class="md:hidden bg-white shadow-md px-6 py-4 space-y-4"
      >
        <router-link
          to="/"
          class="block nav-link"
          :class="{ 'active': isActive('/') }"
          @click="closeMenu"
        >
          Home
        </router-link>
        <!-- <router-link
          to="/about"
          class="block nav-link"
          :class="{ 'active': isActive('/about') }"
          @click="closeMenu"
        >
          About Us
        </router-link> -->
        <router-link
          to="/service"
          class="block nav-link"
          :class="{ 'active': isActive('/service') }"
          @click="closeMenu"
        >
          Services
        </router-link>
        <!-- <router-link
          to="/contact"
          class="block nav-link"
          :class="{ 'active': isActive('/contact') }"
          @click="closeMenu"
        >
          Contact
        </router-link> -->
      </div>
      <!-- Scroll Indicator -->
    <ScrollIndicator />
    </nav>
  </template>

  <script lang="ts" setup>
  import { ref } from 'vue';
  import { useRoute } from 'vue-router';
  import ScrollIndicator from './ScrollIndicator.vue';

  // Get the current route to highlight active links
  const route = useRoute();
  const menuOpen = ref(false); // Toggle for the mobile menu

  // Function to check if a route is active
  const isActive = (path: string) => {
    return route.path === path;
  };

  // Toggle the mobile menu visibility
  const toggleMenu = () => {
    menuOpen.value = !menuOpen.value;
  };

  // Close the mobile menu when a link is clicked
  const closeMenu = () => {
    menuOpen.value = false;
  };
  </script>

  <style scoped>
  /* Tailwind is used for layout and basic styles */
  .nav-link {
    @apply text-gray-700 hover:text-blue-500 font-medium transition duration-300;
  }

  .active {
    @apply text-blue-600 font-bold border-b-4 border-blue-600;
  }

  </style>

<template>
  <div id="app" class="flex flex-col min-h-screen">
    <!-- Navbar is included here -->
    <Navbar />

    <!-- Main content section -->
    <main class="flex-grow content">
      <!-- This is where the content of each route will be rendered -->
      <router-view />
    </main>

    <!-- Footer is included here -->
    <FooterComponent />

    <!-- Scroll-to-Top Button -->
    <ScrollToTop />
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue';
import FooterComponent from './components/FooterComponent.vue';
import ScrollToTop from './components/ScrollToTop.vue';

export default {
  name: 'App',
  components: {
    Navbar,
    FooterComponent,
    ScrollToTop,
  },
};
</script>

<style scoped>
/* Padding for content to avoid being hidden behind the fixed navbar */
.content {
  padding-top: 60px; /* Adjust this value based on the height of your navbar */
}

/* Media query to adjust padding for larger screens */
@media (min-width: 768px) {
  .content {
    padding-top: 65px; /* Adjusted padding for medium and larger screens */
  }
}
</style>

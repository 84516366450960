import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-454b5d63"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "fixed bottom-8 right-8"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.showButton)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.scrollToTop && $setup.scrollToTop(...args))),
          class: "bg-black hover:bg-gray-800 text-white font-bold p-3 rounded-full shadow-lg transition-transform transform hover:scale-110 focus:outline-none focus:ring-4 focus:ring-gray-400",
          "aria-label": "Scroll to top"
        }, " ⬆️ ")
      ]))
    : _createCommentVNode("", true)
}
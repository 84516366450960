import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1e09d565"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "service-cards-container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeroSection = _resolveComponent("HeroSection")!
  const _component_ServiceCard = _resolveComponent("ServiceCard")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_HeroSection, {
      title: "Our Services",
      subtitle: "Offering a range of web and mobile development solutions",
      backgroundImage: require('@/assets/ziontechug.jpg')
    }, null, 8, ["backgroundImage"]),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.services, (service) => {
        return (_openBlock(), _createBlock(_component_ServiceCard, {
          key: service.id,
          title: service.title,
          description: service.description,
          iconClass: service.iconClass
        }, null, 8, ["title", "description", "iconClass"]))
      }), 128))
    ])
  ]))
}
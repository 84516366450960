import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7600046b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "md:bg-[url('@/assets/mobil2.jpg')] bg-[url('@/assets/Home1.jpg')] bg-cover overflow-hidden h-screen" }
const _hoisted_2 = { class: "text-white h-[100dvh]" }
const _hoisted_3 = { class: "bg-gray-800 bg-opacity-60 h-full flex justify-center items-center flex-col" }
const _hoisted_4 = { class: "jovic md:text-5xl sm:text-5xl lg:text-6xl text-2xl font-extrabold text-white pt-28" }
const _hoisted_5 = { class: "biralo md:text-4xl pt-2 font-light text-lg text-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AboutComponent = _resolveComponent("AboutComponent")!
  const _component_CTAComponent = _resolveComponent("CTAComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.displayedTitle), 1),
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.displayedSubtitle), 1)
        ])
      ])
    ]),
    _createVNode(_component_AboutComponent),
    _createVNode(_component_CTAComponent)
  ], 64))
}
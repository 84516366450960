    <template>
      <!-- FIRST SECTION OF THE HOME PAGE -->
      <div class="md:bg-[url('@/assets/mobil2.jpg')] bg-[url('@/assets/Home1.jpg')] bg-cover overflow-hidden h-screen">
        <div class="text-white h-[100dvh]">
          <div class="bg-gray-800 bg-opacity-60 h-full flex justify-center items-center flex-col">
            <p class="jovic md:text-5xl sm:text-5xl lg:text-6xl text-2xl font-extrabold text-white pt-28">{{ displayedTitle }}</p>
            <p class="biralo md:text-4xl pt-2 font-light text-lg text-white">{{ displayedSubtitle }}</p>
          </div>
        </div>
      </div>
      <!-- about section -->
      <AboutComponent />
      <!-- Call to Action section -->
    <CTAComponent />
    </template>

    <script lang="ts">
    import { defineComponent, onMounted, ref } from 'vue';
    import AboutComponent from '@/components/AboutComponent.vue';
    import CTAComponent from '@/components/CTAComponent.vue';

    export default defineComponent({
      components: {
        AboutComponent,
        CTAComponent,
      },
      name: 'WelcomeComponent',
      setup() {
        const fullTitle = 'ZION TECH UGANDA';
        const fullSubtitle = 'Grow Your Business Online';
        const displayedTitle = ref('');
        const displayedSubtitle = ref('');

        const typeWriterEffect = (text: string, displayRef: any, delay: number) => {
          let index = 0;
          const interval = setInterval(() => {
            if (index < text.length) {
              displayRef.value += text.charAt(index);
              index++;
            } else {
              clearInterval(interval);
            }
          }, delay);
        };

        onMounted(() => {
          // Set the displayed title and subtitle with typewriter effect
          typeWriterEffect(fullTitle, displayedTitle, 100); // Adjust delay as needed
          setTimeout(() => {
            typeWriterEffect(fullSubtitle, displayedSubtitle, 100); // Adjust delay as needed
          }, fullTitle.length * 100 + 500); // Delay for subtitle after title
        });

        return {
          displayedTitle,
          displayedSubtitle,
        };
      },
    });
    </script>

    <style scoped>
    /* Fade transition */
    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 0.5s ease;
    }
    .fade-enter,
    .fade-leave-to {
      opacity: 0;
    }

    /* Hover effect */
    .hover\:scale-105:hover {
      transform: scale(1.05);
    }
    </style>

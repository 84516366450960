    <template>
        <div v-if="showButton" class="fixed bottom-8 right-8">
        <button
            @click="scrollToTop"
            class="bg-black hover:bg-gray-800 text-white font-bold p-3 rounded-full shadow-lg transition-transform transform hover:scale-110 focus:outline-none focus:ring-4 focus:ring-gray-400"
            aria-label="Scroll to top"
        >
            <!-- Using Unicode Up Arrow Icon -->
            ⬆️
        </button>
        </div>
    </template>
    
    <script lang="ts">
    import { ref, onMounted, onUnmounted } from 'vue';
    
    export default {
        name: 'ScrollToTop',
        setup() {
        const showButton = ref(false);
    
        const handleScroll = () => {
            showButton.value = window.scrollY > 250; // Show button after 300px scroll
        };
    
        const scrollToTop = () => {
            window.scrollTo({
            top: 0,
            behavior: 'smooth',
            });
        };
    
        onMounted(() => {
            window.addEventListener('scroll', handleScroll);
        });
    
        onUnmounted(() => {
            window.removeEventListener('scroll', handleScroll);
        });
    
        return { showButton, scrollToTop };
        },
    };
    </script>
    
    <style scoped>
    /* Styling the button for better visibility */
    .fixed button {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    }
    </style>
    
    <template>
        <footer class="bg-gray-800 text-white py-6">
        <div class="container mx-auto flex flex-col md:flex-row justify-between items-center px-4">
            <div class="text-center md:text-left">
            <p class="text-sm">© 2024 Zion Tech Uganda. All rights reserved.</p>
            </div>
            <div class="flex justify-center md:justify-end mt-4 md:mt-0">
            <ul class="flex space-x-4">
                <li>
                <router-link to="/" class="hover:underline">Home</router-link>
                </li>
                <li>
                <router-link to="/service" class="hover:underline">Services</router-link>
                </li>
                <!-- <li><router-link to="/contact" class="hover:underline">Contact</router-link></li> -->
            </ul>
            </div>
            <div class="flex space-x-4 mt-4 md:mt-0">
            <!-- WhatsApp link -->
            <a href="https://wa.me/+256776546326" target="_blank" aria-label="WhatsApp">
            <svg class="w-6 h-6 text-white hover:text-gray-400" fill="currentColor" viewBox="0 0 24 24">
                <path d="M17.472 14.28c-.297-.149-1.758-.867-2.031-.967-.273-.099-.472-.149-.67.15-.199.297-.768.967-.94 1.164-.173.197-.347.223-.644.075-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.52.149-.174.199-.298.298-.497.1-.198.05-.373-.025-.521-.075-.149-.67-1.612-.917-2.211-.242-.579-.486-.5-.67-.51-.173-.008-.372-.01-.571-.01s-.52.074-.793.372c-.273.297-1.04 1.016-1.04 2.477 0 1.46 1.064 2.874 1.213 3.074.149.198 2.093 3.22 5.063 4.507.707.305 1.26.487 1.691.622.71.226 1.356.194 1.87.118.57-.084 1.758-.719 2.007-1.412.248-.694.248-1.29.174-1.412-.074-.123-.272-.198-.57-.347zM12 2C6.477 2 2 6.477 2 12c0 1.9.557 3.683 1.531 5.182L2 22l4.807-1.487A9.964 9.964 0 0 0 12 22c5.523 0 10-4.477 10-10S17.523 2 12 2z"/>
            </svg>
            </a>

            <a href="https://x.com/ZionTechUg" aria-label="Twitter">
                <svg class="w-6 h-6 text-white hover:text-gray-400" fill="currentColor" viewBox="0 0 24 24">
                <path d="M24 4.556c-.883.392-1.83.654-2.825.775 1.014-.609 1.794-1.57 2.163-2.724-.95.56-2.004.973-3.127 1.194-.896-.954-2.173-1.549-3.593-1.549-2.718 0-4.925 2.207-4.925 4.925 0 .387.044.765.128 1.126C7.691 8.094 4.066 6.13 1.64 3.161c-.428.733-.671 1.58-.671 2.485 0 1.715.872 3.224 2.189 4.116-.807-.025-1.566-.247-2.229-.617v.062c0 2.394 1.7 4.389 3.952 4.842-.414.112-.848.171-1.297.171-.316 0-.624-.031-.926-.087.625 1.953 2.444 3.375 4.598 3.414-1.684 1.319-3.804 2.106-6.095 2.106-.396 0-.789-.023-1.175-.069 2.188 1.398 4.785 2.208 7.578 2.208 9.099 0 14.088-7.538 14.088-14.086 0-.215-.005-.429-.014-.641.967-.699 1.8-1.57 2.464-2.571z"/>
                </svg>
            </a>
            
            </div>
        </div>
        </footer>
    </template>
    
    <script lang="ts">
    import { defineComponent } from 'vue';
    
    export default defineComponent({
        name: 'FooterComponent',
    });
    </script>
    
    <style scoped>
    .footer {
        background-color: #1f2937; /* Tailwind gray-800 */
    }
    
    .footer a {
        color: #ffffff; /* White */
    }
    
    .footer a:hover {
        color: #d1d5db; /* Tailwind gray-400 */
    }
    </style>
    
    <template>
        <div class="bg-gray-700 text-white py-10 text-center">
        <h2 class="text-4xl font-bold mb-4">{{ title }}</h2>
        <p class="mb-6 text-lg">{{ subtitle }}</p>
        <div class="flex justify-center gap-6">
            <router-link 
            to="/service" 
            class="bg-blue-600 text-white px-6 py-3 rounded-lg flex items-center gap-2 hover:bg-blue-500 transition duration-300 shadow-lg"
            >
            <svg 
                class="w-5 h-5" 
                fill="currentColor" 
                viewBox="0 0 24 24"
            >
                <path d="M12 2c-5.523 0-10 4.477-10 10s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm1 17.93c-1.153.045-2.095-.233-2.793-.67l2.262-2.262c.183.047.378.075.576.075 1.93 0 3.5-1.57 3.5-3.5 0-1.208-.575-2.288-1.453-2.924l2.153-2.153C18.832 14.694 20 16.156 20 18c0 2.21-1.79 4-4 4-1.844 0-3.406-1.313-3.87-3.073l-2.94 2.94A9.91 9.91 0 0 1 12 19.93z" />
            </svg>
            Our Services
            </router-link>
        </div>
        </div>
    </template>
    
    <script lang="ts">
    import { defineComponent } from 'vue';
    
    export default defineComponent({
        name: 'CTAComponent',
        props: {
        title: {
            type: String,
            default: 'Elevate Your Business with Us',
        },
        subtitle: {
            type: String,
            default: 'Expert Web and Mobile App Solutions Tailored for You.',
        },
        },
    });
    </script>
    
    <style scoped>
    /* Add styles if needed */
    </style>
    
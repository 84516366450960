import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-835f4042"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "icon mb-4 text-4xl text-blue-600",
  ref: "icon"
}
const _hoisted_2 = { class: "text-2xl font-semibold mb-2 text-center" }
const _hoisted_3 = { class: "text-gray-600 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "card",
    class: "service-card bg-white shadow-lg rounded-lg p-6 transform hover:scale-105 transition-transform duration-300 flex flex-col items-center",
    onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.rotateIcon(true))),
    onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.rotateIcon(false)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("i", {
        class: _normalizeClass(_ctx.iconClass)
      }, null, 2)
    ], 512),
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.description), 1)
  ], 544))
}
    <template>
        <section class="bg-gray-100 py-16">
        <div class="max-w-6xl mx-auto flex flex-col md:flex-row items-center">
            <!-- Text Section -->
            <div class="md:w-1/2 p-6">
            <h2 class="text-3xl md:text-4xl font-extrabold text-green-600">About Zion Tech Uganda</h2>
            <p class="mt-4 text-lg text-gray-700">
                At Zion Tech Uganda, we are committed to shaping the future of technology. Our mission is to provide innovative IT solutions that empower businesses to optimize their operations and achieve sustainable growth.
            </p>
            <p class="mt-4 text-lg text-gray-700">
                Specializing in software development, IT support, and cloud services, we offer tailored solutions designed to meet the unique needs of each client. Partner with us to transform your vision into reality.
            </p>
            </div>
    
            <!-- Image Section -->
            <div class="md:w-1/2 p-6">
            <img src="@/assets/it.jpeg" alt="About Us" class="w-full h-auto rounded-lg shadow-md" />
            </div>
        </div>
        </section>
    </template>
    
    <script lang="ts">
    import { defineComponent } from 'vue';
    
    export default defineComponent({
        name: 'AboutComponent',
    });
    </script>
    
    <style scoped>
    /* Add any specific styles if needed */
    </style>
    
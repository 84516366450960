import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6d8f9678"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "p-8 rounded-lg text-white",
  ref: "textContainer"
}
const _hoisted_2 = { class: "text-5xl font-bold mb-2" }
const _hoisted_3 = { class: "text-xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "hero-section bg-opacity-30 h-96 flex items-center justify-center bg-cover bg-center text-center",
    style: _normalizeStyle({ backgroundImage: `url(${_ctx.backgroundImage})` }),
    ref: "heroSection"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.title), 1),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.subtitle), 1)
    ], 512)
  ], 4))
}
    <template>
        <div>
        <HeroSection
            title="Our Services"
            subtitle="Offering a range of web and mobile development solutions"
            :backgroundImage="require('@/assets/ziontechug.jpg')"
        />
    
        <div class="service-cards-container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-8">
            <ServiceCard
            v-for="service in services"
            :key="service.id"
            :title="service.title"
            :description="service.description"
            :iconClass="service.iconClass"
            />
        </div>
        
        </div>
    </template>
    
    <script lang="ts">
    import { defineComponent, ref } from 'vue';
    import HeroSection from '@/components/HeroSection.vue';
    import ServiceCard from '@/components/ServiceCard.vue';
    
    interface Service {
        id: number;
        title: string;
        description: string;
        iconClass: string; // Add icon class for each service
    }
    
    export default defineComponent({
        components: {
        HeroSection,
        ServiceCard,
        },
        setup() {
        const services = ref<Service[]>([
            { id: 1, title: 'Web Hosting', description: 'Reliable and secure web hosting services.', iconClass: 'fas fa-server' },
            { id: 2, title: 'Web Development', description: 'Professional web development solutions tailored to your needs.', iconClass: 'fas fa-code' },
            { id: 3, title: 'Mobile Development', description: 'Cross-platform mobile applications for your business.', iconClass: 'fas fa-mobile-alt' },
            { id: 4, title: 'SEO Optimization', description: 'Improve your website’s visibility with our SEO services.', iconClass: 'fas fa-search' },
            { id: 5, title: 'E-commerce Solutions', description: 'Custom e-commerce websites to boost your online sales.', iconClass: 'fas fa-shopping-cart' },
            { id: 6, title: 'UI/UX Design', description: 'Designing user-friendly interfaces and experiences.', iconClass: 'fas fa-paint-brush' },
        ]);
    
        return { services };
        },
    });
    </script>
    
    <style scoped>
    /* Add any additional page-specific styles here */
    .service-cards-container {
        margin: 0 auto; /* Center the card container */
        max-width: 1200px; /* Set a maximum width for large screens */
    }
    </style>
    
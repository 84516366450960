    <template>
        <div class="scroll-indicator" :style="{ width: indicatorWidth }"></div>
    </template>
    
    <script>
    export default {
        data() {
        return {
            scrollHeight: 0,
            scrollTop: 0,
            clientHeight: 0,
        };
        },
        computed: {
        indicatorWidth() {
            return `${(this.scrollTop / (this.scrollHeight - this.clientHeight)) * 100}%`;
        },
        },
        mounted() {
        this.updateScrollInfo();
        window.addEventListener('scroll', this.updateScrollInfo);
        window.addEventListener('resize', this.updateScrollInfo);
        },
        beforeDestroy() {
        window.removeEventListener('scroll', this.updateScrollInfo);
        window.removeEventListener('resize', this.updateScrollInfo);
        },
        methods: {
        updateScrollInfo() {
            this.scrollHeight = document.documentElement.scrollHeight;
            this.scrollTop = window.scrollY;
            this.clientHeight = window.innerHeight;
        },
        },
    };
    </script>
    
    <style scoped>
    .scroll-indicator {
        position: fixed;
        top: 0;
        left: 0;
        height: 5px;
        background: #17ca11; /* Change to your preferred color */
        z-index: 1000; /* Ensure it's above other elements */
        transition: width 0.2s ease-in-out; /* For smooth transitions */
    }
    </style>
    
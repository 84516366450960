    <template>
        <div
        ref="card"
        class="service-card bg-white shadow-lg rounded-lg p-6 transform hover:scale-105 transition-transform duration-300 flex flex-col items-center"
        @mouseenter="rotateIcon(true)"
        @mouseleave="rotateIcon(false)"
        >
        <div class="icon mb-4 text-4xl text-blue-600" ref="icon">
            <i :class="iconClass"></i>
        </div>
        <h2 class="text-2xl font-semibold mb-2 text-center">{{ title }}</h2>
        <p class="text-gray-600 text-center">{{ description }}</p>
        </div>
    </template>
    
    <script lang="ts">
    import { defineComponent, onMounted, ref } from 'vue';
    import { gsap } from 'gsap';
    
    export default defineComponent({
        name: 'ServiceCard',
        props: {
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
        iconClass: {
            type: String,
            required: true,
        },
        },
        setup() {
        const card = ref<HTMLElement | null>(null);
        const icon = ref<HTMLElement | null>(null);
    
        onMounted(() => {
            if (card.value) {
            // Initial animation for the card
            gsap.from(card.value, {
                opacity: 0,
                y: 50,
                duration: 1,
                ease: 'power3.out',
            });
            }
        });
    
        const rotateIcon = (isHovered: boolean) => {
            if (icon.value) {
            gsap.to(icon.value, {
                rotation: isHovered ? 360 : 0,
                duration: 0.5,
                ease: 'power2.out',
            });
            }
        };
    
        return { card, icon, rotateIcon };
        },
    });
    </script>
    
    <style scoped>
    .service-card {
        transition: transform 0.3s;
        /* Add any additional card styling here if needed */
    }
    .icon {
        transition: color 0.3s;
    }
    .service-card:hover .icon {
        color: #2563eb; /* Change color on hover */
    }
    </style>
    
        <template>
            <div
            class="hero-section bg-opacity-30 h-96 flex items-center justify-center bg-cover bg-center text-center"
            :style="{ backgroundImage: `url(${backgroundImage})` }"
            ref="heroSection"
            >
            <div class="p-8 rounded-lg text-white" ref="textContainer">
                <h1 class="text-5xl font-bold mb-2">{{ title }}</h1>
                <p class="text-xl">{{ subtitle }}</p>
            </div>
            </div>
        </template>
        
        <script lang="ts">
        import { defineComponent, PropType, ref, onMounted } from 'vue';
        import { gsap } from 'gsap';
        
        export default defineComponent({
            name: 'HeroSection',
            props: {
            title: {
                type: String,
                required: true,
            },
            subtitle: {
                type: String,
                required: true,
            },
            backgroundImage: {
                type: String,
                required: true,
            },
            },
            setup() {
            const heroSection = ref<HTMLElement | null>(null);
            const textContainer = ref<HTMLElement | null>(null);
        
            onMounted(() => {
                // Zoom in the background image
                gsap.fromTo(
                heroSection.value,
                { scale: 1.2 },
                { scale: 1, duration: 2, ease: 'power2.out' }
                );
        
                // Animate the text
                gsap.from(textContainer.value, {
                y: 50, // Move it down by 50px
                opacity: 0, // Start with opacity 0
                duration: 1.0,
                ease: 'power2.out',
                delay: 0.5, // Delay the text animation
                });
            });
        
            return {
                heroSection,
                textContainer,
            };
            },
        });
        </script>
        
        <style scoped>
        .hero-section {
            height: 24rem; /* 96 in Tailwind's spacing scale */
        }
        </style>
        